import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useHistory, useLocation } from 'react-router'

// Utilities
import _ from 'lodash'
import classnames from 'classnames'
import { updateURLParameter } from '../../../utils/urls'

// Styling
import sharedStyle from '../../../styles/shared.module.css'
import style from './SettingsPage.module.css'

// Components
import { Search } from '@labsavvyapp/ui-components'
import MainContainer from '../../../components/MainContainer/MainContainer'
import MaintenancePage from '../MaintenancePage/MaintenancePage'
import SettingMenuItem from './SettingMenuItem'
import ConfigurationPage from '../ConfigurationPage/ConfigurationPage'
import EmailManagementPage from '../EmailManagementPage/EmailManagementPage'
import AddEditConfigurationPage from '../ConfigurationPage/AddEditConfigurationPage'

// Constants
import { SETTINGS } from '../../../config/routes'
import DataManagementPage from '../DataMangementPage/DataManagementPage'

export default function SettingsPage() {
  const [search, setSearch] = useState()

  const { push } = useHistory()
  const location = useLocation()

  // Does not reset selected settings menu item on page load or refresh
  useEffect(() => {
    let index = _.findIndex(
      menuItems,
      (e) => {
        return e.url == location.pathname
      },
      0,
    )
    if (index === -1) index = 0
    handleMenuSelect(index)
  }, [location.pathname])

  const SettingMenuItems = () => {
    return menuItems.map((menuItem, index) => {
      if (!menuItem.isHidden) {
        return (
          <SettingMenuItem
            data={menuItem}
            handleMenuSelect={handleMenuSelect}
            index={index}
            key={index}
          />
        )
      } else {
        return null
      }
    })
  }

  const handleMenuSelect = (index) => {
    let items = [...menuItems]
    items.map((item) => {
      if (item) {
        item.active = false
      }
    })
    let item = { ...items[index], active: true }
    items[index] = item
    setMenuItems(items)
    setSearch(null)
  }

  function handleSearchChange(value) {
    const urlParams = updateURLParameter('search', value)
    push(`?${urlParams}`)
    setSearch(value)
  }

  const [menuItems, setMenuItems] = useState([
    // Main components: use isHidden=false
    {
      name: 'Maintenance',
      url: SETTINGS.maintenance,
      component: <MaintenancePage key={0} />,
      active: true,
      isHidden: false,
    },
    {
      name: 'Configurations',
      url: SETTINGS.configuration,
      component: <ConfigurationPage search={search} />,
      active: false,
      subcomponents: [SETTINGS.editConfiguration, SETTINGS.addConfiguration],
      isHidden: false,
    },
    {
      name: 'Data Import',
      url: SETTINGS.dataManagement,
      component: <DataManagementPage key={2} />,
      active: false,
      isHidden: false,
    },
    {
      name: 'Email Management',
      url: SETTINGS.emailManagement,
      component: <EmailManagementPage />,
      active: false,
      isHidden: false,
    },

    // Subcomponents: use isHidden=true
    {
      name: 'Add Configuration',
      url: SETTINGS.addConfiguration,
      component: <AddEditConfigurationPage />,
      active: false,
      isHidden: true,
    },
    {
      name: 'Edit Configuration',
      url: SETTINGS.editConfiguration,
      component: <AddEditConfigurationPage />,
      active: false,
      isHidden: true,
    },
  ])

  return (
    <MainContainer>
      <Helmet>
        <title>Settings</title>
      </Helmet>
      <div>
        <header className={style.header}>
          <div style={{ marginTop: 8 }}>
            <h1 className={classnames(sharedStyle.pageTitle, style.pageTitle)}>
              Settings
            </h1>
          </div>
          <div className={style.tools}>
            {location.pathname === SETTINGS.configuration && (
              <>
                <Search
                  className={style.search}
                  placeholder="Search Config"
                  showNoResults={false}
                  searchText={search}
                  onSearchChange={handleSearchChange}
                />
                {/* <Button onClick={() => push(SETTINGS.addConfiguration)}>
                  Add Configuration
                </Button> */}
              </>
            )}
          </div>
        </header>
        <div className={style.settingsPageContainer}>
          <div className={style.settingsMenuList}>
            <SettingMenuItems />
          </div>

          <div className={style.settingsMainArea}>
            {menuItems.map((item) => {
              return item.active && item.component
            })}
          </div>
        </div>
      </div>
    </MainContainer>
  )
}
