import React, { useEffect, useState } from 'react'
import { Button } from '@labsavvyapp/ui-components'
import { Dropdown } from 'semantic-ui-react'
import _ from 'lodash'

export default function DataPartnerPricingRow({ data, setState }) {
  const [partner, setPartner] = useState('')

  useEffect(() => {
    setPartner(data?.data[0])
  }, [data.data])

  const handleChangePartner = async (event, result) => {
    const { value } = result || event.target
    let selected = _.find(data?.data, { _id: value })
    setPartner(selected)
  }

  return (
    <div
      style={{
        flexDirection: 'row',
        display: 'flex',
        paddingTop: 10,
        paddingBottom: 10,
        borderBottom: '1px solid #ECECEB',
      }}
      key={data.index}
    >
      <div
        style={{
          width: '55%',
          flexDirection: 'column',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <div style={{ fontWeight: 600, fontSize: 16 }}>{data.title} </div>
        <div style={{ fontWeight: 200, fontSize: 14 }}>{data.subtitle}</div>
      </div>

      <div style={{ width: '30%', textAlign: 'right', paddingRight: 30 }}>
        <div>
          <span style={{ fontSize: 12, marginRight: 10 }}>
            {data.dataName} :
          </span>
          {data?.data && (
            <Dropdown
              selection
              options={data?.data.map((item) => ({
                text: item.name,
                value: item._id,
                key: item._id,
              }))}
              onChange={handleChangePartner}
              defaultValue={data?.data[0]?._id}
              search
            />
          )}
        </div>
      </div>

      <div
        style={{
          width: '15%',
          textAlign: 'center',
          paddingRight: 30,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Button
          onClick={() => {
            setState({
              pricing: 'partner',
              partner: partner,
            })
            data.action.current.click()
          }}
          size="small"
        >
          {data.actionButtonName}
        </Button>
      </div>
    </div>
  )
}
