import React, { useEffect, useState, useRef, useReducer } from 'react'
import { useSavingModals } from '@labsavvyapp/ui-components'
import { useQuery, useMutation } from 'react-apollo'

// Components
import DataCompendiaRow from './Compendium/DataCompendiaRow'
import DataManagementRow from './MainPricing/DataManagementRow'
import DataPartnerPricingRow from './PartnerPricing/DataPartnerPricingRow'
import ModalCompendiaList from './Compendium/ModalCompendiaList'
import ModalMainPricingList from './MainPricing/ModalMainPricingList'
import ModalPartnerPricingList from './PartnerPricing/ModalPartnerPricingList'

// Utilities
import { read, utils } from 'xlsx'

// Queries and Mutations
import { ListPartners } from '../../../graphql/partner/queries'
import { ListProviders } from '../../../graphql/providers/queries'
import {
  ImportCompendium,
  ImportMainPricing,
  ImportPartnerPricing,
} from '../../../graphql/settings/mutations'

export default function DataManagementPage() {
  const inputFile = useRef(null)

  // Menu Items with no data dependencies
  const [maintenanceItems, setMaintenanceItems] = useState([])

  // Key fields state handler
  const [state, setState] = useReducer((prev, next) => ({ ...prev, ...next }), {
    pricing: 'main',
    partner: '',
    provider: '',
    modal: false,
  })

  // Query handlers
  const { data: { listProviders: providers } = {} } = useQuery(ListProviders, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  })
  const { data: { listPartners: partners } = {} } = useQuery(ListPartners, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  })

  // Mutation handlers
  const [importCompendium, { data: importCompendiumStatus }] =
    useMutation(ImportCompendium)
  const [importMainPricing, { data: importMainPricingStatus }] =
    useMutation(ImportMainPricing)
  const [importPartnerPricing, { data: importPartnerPricingStatus }] =
    useMutation(ImportPartnerPricing)

  // Modals
  const [saveCompendiumModal, { showModals: startImportCompendium }] =
    useSavingModals({
      savingMessage: `We are importing compendium for ${state.provider?.name}. Please wait...`,
      savedMessage: `${importCompendiumStatus?.importCompendium?.message}:
                      ${importCompendiumStatus?.importCompendium?.args[0]},
                      ${importCompendiumStatus?.importCompendium?.args[1]},
                      ${importCompendiumStatus?.importCompendium?.args[2]}`,
      callback: async () => {
        await importCompendium({
          variables: {
            providerId: state.provider?._id,
            input: uploadFileData,
          },
        })
      },
      onError: (error) => error,
    })

  const [saveMainPricingModal, { showModals: startImportMainPricing }] =
    useSavingModals({
      savingMessage: `We're importing main pricing data for ${state.provider?.name}. Please wait...`,
      savedMessage: importMainPricingStatus?.importMainPricing?.message,
      callback: async () => {
        await importMainPricing({
          variables: {
            providerId: state.provider?._id,
            input: uploadFileData,
          },
        })
      },
      onError: (error) => error,
    })

  const [savePartnerPricingModal, { showModals: startImportPartnerPricing }] =
    useSavingModals({
      savingMessage: `We're importing partner pricing data for ${state.partner?.name}. Please wait...`,
      savedMessage: importPartnerPricingStatus?.importPartnerPricing?.message,
      callback: async () => {
        await importPartnerPricing({
          variables: {
            partnerId: state.partner?._id,
            input: uploadFileData,
          },
        })
      },
      onError: (error) => error,
    })

  // Rerender if data dependecies are loaded
  useEffect(() => {
    if (providers && partners) {
      setMaintenanceItems(() => [
        {
          title: 'Import Compendium data from csv or xls file',
          subtitle:
            'Columns: order_code, order_name, result_code, result_name, loinc_code',
          action: inputFile,
          dataName: 'Laboratory',
          data: providers.providers,
          actionButtonName: 'Upload',
          type: 'compendia',
        },
        {
          title: 'Import Main Pricing data from csv or xls file',
          subtitle:
            'Columns: order_code, order_name, provider_price, ls_price, standard_retail_price',
          action: inputFile,
          dataName: 'Laboratory',
          data: providers.providers,
          actionButtonName: 'Upload',
          type: 'main',
        },
        {
          title: 'Import Partner Pricing data from csv or xls file',
          subtitle:
            'Columns: order_code, order_name, contract_price, retail_price',
          action: inputFile,
          dataName: 'Partner',
          data: partners.partners,
          actionButtonName: 'Upload',
          type: 'partner',
        },
      ])
    }
  }, [providers, partners])

  const [uploadFileData, setUploadFileData] = useState()

  const readUploadFile = (e) => {
    e.preventDefault()
    if (e.target.files) {
      const reader = new FileReader()
      reader.onload = (e) => {
        const data = e.target.result
        const workbook = read(data, { type: 'array' })
        const sheetName = workbook.SheetNames[0]
        const worksheet = workbook.Sheets[sheetName]
        const json = utils.sheet_to_json(worksheet)

        // process xls/csv data
        const filtered = json
          .filter((row) => row.result_code !== '#N/A')
          .map((row) => {
            if ('compendia' === state.pricing) {
              row.order_code = row.order_code.toString()
              row.result_code = row.result_code.toString()
              row.loinc_code = row.loinc_code.toString()
            }
            // graphql dependencies - rename fields for main/partner pricing
            else {
              row.test_name = row.order_name
              row.test_code = row.order_code.toString()

              delete row.order_code
              delete row.order_name
            }
            return row
          })

        setUploadFileData(filtered)
        setState({ modal: true })
      }
      reader.readAsArrayBuffer(e.target.files[0])
    }
    // reset input file
    inputFile.current.value = null
  }

  return (
    <div>
      {maintenanceItems?.map((data, index) => {
        switch (data.type) {
          case 'compendia':
            return (
              <DataCompendiaRow data={data} key={index} setState={setState} />
            )
          case 'main':
            return (
              <DataManagementRow data={data} key={index} setState={setState} />
            )
          case 'partner':
            return (
              <DataPartnerPricingRow
                data={data}
                key={index}
                setState={setState}
              />
            )
          default:
            break
        }
      })}

      <input
        type="file"
        id="file"
        ref={inputFile}
        onChange={readUploadFile}
        style={{ display: 'none' }}
        accept=".xlsx, .xls, .csv"
      />

      <ModalCompendiaList
        show={state.pricing === 'compendia' && state.modal}
        provider={state.provider}
        uploadFileData={uploadFileData}
        setState={setState}
        importPricing={startImportCompendium}
      />
      <ModalMainPricingList
        show={state.pricing === 'main' && state.modal}
        provider={state.provider}
        uploadFileData={uploadFileData}
        setState={setState}
        importPricing={startImportMainPricing}
      />
      <ModalPartnerPricingList
        show={state.pricing === 'partner' && state.modal}
        partner={state.partner}
        uploadFileData={uploadFileData}
        setState={setState}
        importPricing={startImportPartnerPricing}
      />

      {saveCompendiumModal}
      {saveMainPricingModal}
      {savePartnerPricingModal}
    </div>
  )
}
