import React, { useEffect, useState } from 'react'
import {
  Input,
  Modal,
  ModalHeader,
  ModalContent,
  ModalActions,
} from 'semantic-ui-react'
import classnames from 'classnames'

import { Button, ListRow } from '@labsavvyapp/ui-components'
import sharedStyle from '../../../../styles/shared.module.css'

// Styles
import style from '../DataManagementPage.module.css'

const ModalPartnerPricingList = ({
  show,
  partner,
  uploadFileData,
  setState,
  importPricing,
}) => {
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    setShowModal(show)
  }, [show, uploadFileData])

  return (
    <Modal
      size="fullscreen"
      open={showModal}
      onClose={() => setState({ modal: false })}
    >
      <ModalHeader>
        {uploadFileData && (
          <div
            style={{
              display: 'flex',
              backgroundColor: '#ECECEC',
              fontSize: 13,
              padding: 10,
            }}
          >
            <div style={{ width: '10%' }}>
              <b>Order Code</b>
            </div>
            <div style={{ width: '70%' }}>
              <b>Order Name</b>
            </div>
            <div style={{ width: '10%' }}>
              <b>Contract Price</b>
            </div>
            <div style={{ width: '10%' }}>
              <b>Retail Price</b>
            </div>
          </div>
        )}
      </ModalHeader>
      <ModalContent scrolling>
        {uploadFileData &&
          uploadFileData.map((item, index) => {
            return (
              <ListRow
                key={index}
                className={classnames(sharedStyle.listRow, style.row)}
              >
                <div className={style.column} style={{ width: '10%' }}>
                  {item.test_code}
                </div>
                <div className={style.column} style={{ width: '70%' }}>
                  {item.test_name}
                </div>
                <div className={style.column} style={{ width: '10%' }}>
                  <Input
                    name="contract_price"
                    icon="dollar"
                    iconPosition="left"
                    type="number"
                    placeholder={0}
                    value={item.contract_price}
                    style={{ cursor: 'default' }}
                    // onChange={(e) => handleSaveMainPricing(item, e)}
                  />
                </div>
                <div className={style.column} style={{ width: '10%' }}>
                  <Input
                    name="retail_price"
                    icon="dollar"
                    iconPosition="left"
                    type="number"
                    placeholder={0}
                    value={item.retail_price}
                    style={{ cursor: 'default' }}
                    // onChange={(e) => handleSaveMainPricing(item, e)}
                  />
                </div>
              </ListRow>
            )
          })}
      </ModalContent>
      <ModalActions
        style={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'flex-end',
          paddingTop: 30,
        }}
      >
        <div style={{ marginRight: 10 }}>
          This will attempt to import <b>{uploadFileData?.length}</b> partner
          pricing data to <b>{partner?.name}</b>
        </div>
        <Button
          onClick={() => {
            setState({ modal: false })
            importPricing()
          }}
        >
          Import Data
        </Button>
        <Button
          className={style.ml5}
          onClick={() => setState({ modal: false })}
          variant={Button.variant.secondary}
        >
          Cancel
        </Button>
      </ModalActions>
    </Modal>
  )
}

export default ModalPartnerPricingList
