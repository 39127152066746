import React, { useEffect, useState } from 'react'
import { Button } from '@labsavvyapp/ui-components'
import { Dropdown } from 'semantic-ui-react'
import _ from 'lodash'

export default function DataCompendiaRow({ data, setState }) {
  const [provider, setProvider] = useState('')

  useEffect(() => {
    setProvider(data?.data[0])
  }, [data.data])

  const handleDropdownSelection = async (event, result) => {
    const { value } = result || event.target
    let selected = _.find(data.data, { _id: value })
    setProvider(selected)
  }

  return (
    <div
      style={{
        flexDirection: 'row',
        display: 'flex',
        paddingTop: 10,
        paddingBottom: 10,
        borderBottom: '1px solid #ECECEB',
      }}
      key={data.index}
    >
      <div style={{ width: '55%' }}>
        <div style={{ fontWeight: 600, fontSize: 16 }}>{data.title} </div>
        <div style={{ fontWeight: 200, fontSize: 14 }}>{data.subtitle}</div>
      </div>

      <div style={{ width: '30%', textAlign: 'right', paddingRight: 30 }}>
        <span style={{ fontSize: 12, marginRight: 10 }}>{data.dataName} :</span>
        {data?.data ? (
          <Dropdown
            selection
            options={data?.data?.map((item) => ({
              text: item.name,
              value: item._id,
              key: item._id,
            }))}
            onChange={handleDropdownSelection}
            defaultValue={data?.data[0]?._id}
            search
          />
        ) : (
          <></>
        )}
      </div>

      <div style={{ width: '15%', textAlign: 'center', paddingRight: 30 }}>
        <Button
          onClick={() => {
            setState({
              pricing: 'compendia',
              provider: provider,
            })
            data.action.current.click()
          }}
          size="small"
        >
          {data.actionButtonName}
        </Button>
      </div>
    </div>
  )
}
