import React, { useEffect, useState } from 'react'
import {
  Input,
  Modal,
  ModalHeader,
  ModalContent,
  ModalActions,
} from 'semantic-ui-react'
import classnames from 'classnames'

import { Button, Form, ListRow } from '@labsavvyapp/ui-components'
import sharedStyle from '../../../../styles/shared.module.css'

// Styles
import style from '../DataManagementPage.module.css'

const ModalMainPricingList = ({
  show,
  provider,
  uploadFileData,
  setState,
  importPricing,
}) => {
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    setShowModal(show)
  }, [show, uploadFileData])

  return (
    <Modal
      size="fullscreen"
      open={showModal}
      onClose={() => setState({ modal: false })}
    >
      <ModalHeader>
        {uploadFileData && (
          <div
            style={{
              display: 'flex',
              backgroundColor: '#ECECEC',
              fontSize: 13,
              padding: 10,
            }}
          >
            <div style={{ width: '10%' }}>
              <b>Order Code</b>
            </div>
            <div style={{ width: '51%' }}>
              <b>Order Name</b>
            </div>
            <div style={{ width: '13%' }}>
              <b>LS Cost</b>
            </div>
            <div style={{ width: '13%' }}>
              <b>LS Standard Price</b>
            </div>
            <div style={{ width: '13%' }}>
              <b>Standard Retail Price</b>
            </div>
          </div>
        )}
      </ModalHeader>
      <ModalContent scrolling>
        {uploadFileData &&
          uploadFileData.map((item, index) => {
            return (
              <ListRow
                key={index}
                className={classnames(sharedStyle.listRow, style.row)}
              >
                <div className={style.firstColumn}>{item.test_code}</div>
                <div className={style.secondColumn}>{item.test_name}</div>

                <div className={style.thirdColumn}>
                  <Form>
                    <Input
                      name="provider_price"
                      icon="dollar"
                      iconPosition="left"
                      type="number"
                      placeholder={0}
                      value={item.provider_price}
                      style={{ cursor: 'default' }}
                      // onChange={(e) => handleSaveMainPricing(item, e)}
                    />
                  </Form>
                </div>
                <div className={style.fourthColumn}>
                  <Form>
                    <Input
                      name="ls_price"
                      icon="dollar"
                      iconPosition="left"
                      type="number"
                      placeholder={0}
                      value={item.ls_price}
                      style={{ cursor: 'default' }}
                      // onChange={(e) => handleSaveMainPricing(item, e)}
                    />
                  </Form>
                </div>
                <div className={style.fifthColumn}>
                  <Form>
                    <Input
                      name="standard_retail_price"
                      icon="dollar"
                      iconPosition="left"
                      type="number"
                      placeholder={0}
                      value={item.standard_retail_price}
                      style={{ cursor: 'default' }}
                      // onChange={(e) => handleSaveMainPricing(item, e)}
                    />
                  </Form>
                </div>
              </ListRow>
            )
          })}
      </ModalContent>
      <ModalActions style={{ paddingTop: 30 }}>
        This will attempt to import <b>{uploadFileData?.length}</b> main pricing
        data to <b style={{ paddingRight: 10 }}>{provider?.name}</b>
        <Button
          onClick={() => {
            setState({ modal: false })
            importPricing()
          }}
        >
          Import Data
        </Button>
        <Button
          className={style.ml5}
          onClick={() => setState({ modal: false })}
          variant={Button.variant.secondary}
        >
          Cancel
        </Button>
      </ModalActions>
    </Modal>
  )
}

export default ModalMainPricingList
